const Home = () => import(/* webpackChunkName: "home" */ './Index.vue')

const index = {
  path: 'dashboard',
  name: 'Dashboard',
  component: Home,
  meta: {
    icon: 'fas fa-chart-line',
    shown: true
  }
}

export default {
  index
}
