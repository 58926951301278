import { isProxy, toRaw } from 'vue'
import { colors } from '../models/Sector'
import moment from 'moment'

const getObject = (value) => {
  return isProxy(value) ? toRaw(value) : value
}

const toCapitalize = function (allWords) {
  return (allWords) ? this.split(' ').map(word => word.toCapitalize()).join(' ') : this.charAt(0).toUpperCase() + this.slice(1)
}

const destructuringDataChartSector = (array) => {
  const chart = {
    colors: [],
    series: [],
    labels: []
  }
  for (const sector of array) {
    const color = colors.find(color => (color.value === sector.color))
    chart.colors = [...chart.colors, color.hex]
    chart.labels = [...chart.labels, sector.nameSector]
    chart.series = [...chart.series, sector.quantity]
  }
  return chart
}

const destructuringDataChartCards = (array, type = 'Demandas') => {
  const series = [{
    name: type,
    data: []
  }]
  for (const card of array) {
    series[0].data = [...series[0].data, {
      x: (moment(card.reference, 'YYYY-MM-DD').locale('pt-BR').format('MMMM - YYYY')).toCapitalize(),
      y: card.quantity
    }]
  }
  return series
}

const findColorByName = (name) => {
  return colors.find(color => color.value === name)
}

const destructuringDataChartFilter = (array, idFilter) => {
  const chart = {
    colors: [],
    series: [],
    labels: []
  }

  const filterColors = () => {
    let colors = []
    if (parseInt(idFilter) === 1) {
      for (let index = 0; index < 5; index++) {
        /* Azul */
        if (index === 0) {
          colors = [...colors, findColorByName('blue').hex]
        /* Verde */
        } else if (index === 1) {
          colors = [...colors, findColorByName('green').hex]
        /* Amarelo */
        } else if (index === 2) {
          colors = [...colors, findColorByName('yellow').hex]
        /* Laranja */
        } else if (index === 3) {
          colors = [...colors, findColorByName('orange').hex]
        /* Vermelho */
        } else {
          colors = [...colors, findColorByName('red').hex]
        }
      }
    } else {
      for (let index = 0; index < 5; index++) {
        /* Vermelho */
        if (index === 0) {
          colors = [...colors, findColorByName('red').hex]
        /* Verde */
        } else if (index === 1) {
          colors = [...colors, findColorByName('green').hex]
        /* Azul Piscina */
        } else if (index === 2) {
          colors = [...colors, findColorByName('sky').hex]
        /* Azul */
        } else {
          colors = [...colors, findColorByName('blue').hex]
        }
      }
    }
    return colors
  }

  for (let index = 0; index < array.length; index++) {
    chart.colors = filterColors()
    chart.labels = [...chart.labels, array[index].designatedName]
    chart.series = [...chart.series, array[index].quantity]
  }
  return chart
}

const destructuringDataChartMembers = (array, type = 'Demandas') => {
  const series = [{
    name: type,
    data: []
  }]
  for (const card of array) {
    series[0].data = [...series[0].data, {
      x: card.fullName,
      y: card.quantity
    }]
  }
  return series
}

const destructuringDataChartMembersAverage = (array, type = 'Média') => {
  const series = [{
    name: type,
    data: []
  }]
  for (const card of array) {
    series[0].data = [...series[0].data, {
      x: card.fullName,
      y: card.average.toFixed(2)
    }]
  }
  return series
}

const destructuringDataChartRepresentation = (array) => {
  const chart = {
    colors: ['#EF7564', '#FFAF3F', '#F5DD29', '#CD8DE5', '#29CCE5', '#172B4D', '#6DECA9', '#FF8ED4', '#5BA4CF', '#7BC86C'],
    series: [],
    labels: []
  }

  for (let index = 0; index < array.length; index++) {
    chart.labels = [...chart.labels, array[index].fullName]
    chart.series = [...chart.series, parseFloat(array[index].representation.toFixed(2))]
  }
  return chart
}

export default {
  getObject,
  toCapitalize,
  destructuringDataChartSector,
  destructuringDataChartCards,
  destructuringDataChartFilter,
  destructuringDataChartMembers,
  destructuringDataChartMembersAverage,
  destructuringDataChartRepresentation
}

export {
  getObject,
  toCapitalize,
  destructuringDataChartSector,
  destructuringDataChartCards,
  destructuringDataChartFilter,
  destructuringDataChartMembers,
  destructuringDataChartMembersAverage,
  destructuringDataChartRepresentation
}