import cpfCnpj from './cpfCnpj'
import date from './date'
import fone from './fone'
import decimal from './decimal'
import currency from './currency'
import cep from './cep'
import timeStamp from './timeStamp'
import timeStampNow from './timeStampNow'

export default {
  cpfCnpj,
  date,
  fone,
  decimal,
  currency,
  cep,
  timeStamp,
  timeStampNow
}
