const authToken = JSON.parse(window.localStorage.getItem('authToken')) || null
const user = JSON.parse(window.localStorage.getItem('user')) || null
const darkMode = JSON.parse(window.localStorage.getItem('darkMode')) || false
const idBoard = JSON.parse(window.localStorage.getItem('tIdBoard')) || null

const state = {
  authToken,
  user,
  darkMode,
  idBoard
}

const mutations = {
  setAuthToken (state, value) {
    if (value) {
      window.localStorage.setItem('authToken', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('authToken')
    }
    state.authToken = value || null
  },
  setUser (state, value) {
    if (value) {
      window.localStorage.setItem('user', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('user')
    }
    state.user = value || null
  },
  setDarkMode (state, value) {
    if (value) {
      window.localStorage.setItem('darkMode', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('darkMode')
    }
    state.darkMode = value
  },
  setIdBoard (state, value) {
    if (value) {
      window.localStorage.setItem('tIdBoard', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('tIdBoard')
    }
    state.idBoard = value
  }
}

const actions = {
  setAuthToken ({ commit }, value) {
    commit('setAuthToken', value)
  },
  getAuthToken ({ commit }) {
    commit('getAuthToken')
  },
  setUser ({ commit }, value) {
    commit('setUser', value)
  },
  getUser ({ commit }) {
    commit('getUser')
  },
  setDarkMode ({ commit }, value) {
    commit('setDarkMode', value)
  },
  getDarkMode ({ commit }) {
    commit('getDarkMode')
  },
  setIdBoard ({ commit }, value) {
    commit('setIdBoard', value)
  },
  getIdBoard ({ commit }) {
    commit('getIdBoard')
  }
}

const getters = {
  getAuthToken () {
    return state.authToken
  },
  getUser () {
    return state.user
  },
  getDarkMode () {
    return state.darkMode
  },
  getIdBoard() {
    return state.idBoard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
