import axios from 'axios'
import configs from '../../configs'

const instance = axios.create({
  baseURL: configs.API_IP,
  headers: { Authorization: configs.TOKEN_API }
})

export default instance

export {
  instance
}