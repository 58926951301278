import store from '../store/index'
import { sendNotification } from '../plugins/notify'

const beforeEach = (to, from, next) => {
  const authToken = store.state.main.authToken
  if (!to.matched.length) {
    next({ path: '/' })
  } else {
    if (!authToken && (to.fullPath !== '/login')) {
      sendNotification({ color: 'info', message: 'Efetue o login no sistema.', timeout: 6000 })
      console.error('Você deve estar logado para acessar esse módulo.')
      next({ path: '/login' })
    } else {
      next()
    }
  }
}

export default beforeEach
