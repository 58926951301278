const LabelFilters = () => import(/* webpackChunkName: "labelFilters" */ './Index.vue')

const index = {
  path: 'filters',
  name: 'Associação de Etiquetas',
  component: LabelFilters,
  meta: {
    icon: 'fas fa-filter', 
    shown: true
  }
}

export default {
  index
}
