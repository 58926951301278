import { required, minLength, maxLength } from '@vuelidate/validators'

const colors = [{
    value: 'green',
    hex: '#7BC86C',
    label: 'Verde'
  }, {
    value: 'blue',
    hex: '#5BA4CF',
    label: 'Azul'
  }, {
    value: 'purple',
    hex: '#CD8DE5',
    label: 'Roxo'
  }, {
    value: 'red',
    hex: '#EF7564',
    label: 'Vermelho'
  }, {
    value: 'black',
    hex: '#172B4D',
    label: 'Azul Escuro'
  }, {
    value: 'sky',
    hex: '#29CCE5',
    label: 'Azul Piscina'
  }, {
    value: 'yellow',
    hex: '#F5DD29',
    label: 'Amarelo'
  }, {
    value: 'orange',
    hex: '#FFAF3F',
    label: 'Laranja'
  }, {
    value: 'lime',
    hex: '#6DECA9',
    label: 'Verde Limão'
  }, {
    value: 'pink',
    hex: '#FF8ED4',
    label: 'Rosa'
  }
]

class Sector {
  constructor () {
    this.nameSector = ''
    this.keyword = ''
    this.color = ''
  }
}

const SectorValidation = {
  nameSector: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(20)
  },
  keyword: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(20)
  },
  color: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(10)
  }
}

export {
  colors,
  Sector,
  SectorValidation
}