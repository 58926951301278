<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
window.FontAwesomeConfig = {
  searchPseudoElements: true
}
export default {
  name: 'app',
  data () {
    return {
      refreshing: false
    }
  },
  created () {
    this.notifyUpdate()
  },
  mounted () {
    this.verifyMode()
  },
  methods: {
    notifyUpdate () {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) {
            return
          }
          if (window.confirm('Existe uma atualização disponível... Clique em "OK" para atualizar!')) {
            this.refreshing = true
            window.location.reload(true)
          }
        })
      }
    },
    verifyMode () {
      const darkMode = this.isDarkMode || false
      this.$q.dark.set(darkMode)
    }
  },
  computed: {
    isDarkMode () {
      return this.$store.getters['main/getDarkMode']
    }
  }
}
</script>
