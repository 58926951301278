const Boards = () => import(/* webpackChunkName: "boards" */ './Index.vue')

const index = {
  path: 'boards',
  name: 'Boards',
  component: Boards,
  meta: {
    icon: 'fas fa-list-alt',
    shown: true
  }
}

export default {
  index
}
