const Labels = () => import(/* webpackChunkName: "labels" */ './Index.vue')

const index = {
  path: 'labels',
  name: 'Etiquetas',
  component: Labels,
  meta: {
    icon: 'fas fa-tags',
    shown: true
  }
}

export default {
  index
}
