const Login = () => import(/* webpackChunkName: "login" */ './Index.vue')

const index = {
  path: 'login',
  name: 'Login',
  component: Login,
  meta: {
    icon: 'fas fa-user-shield',
    shown: false
  }
}

export default {
  index
}
