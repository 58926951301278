const Sectors = () => import(/* webpackChunkName: "sectors" */ './Index.vue')

const index = {
  path: 'sectors',
  name: 'Setores',
  component: Sectors,
  meta: {
    icon: 'fas fa-building',
    shown: true
  }
}

export default {
  index
}
