import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import './registerServiceWorker'
import router from './router/index'
import store from './store/index'
import filters from './filters/index'
import { MoneyDirective } from './directives/index'
import http from './plugins/http'
import interceptor from './plugins/http/interceptor'
import { getObject, toCapitalize } from './utils/utils'
import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue3-apexcharts'

const app = createApp(App)
app.config.globalProperties.$http = http
interceptor()
app.config.globalProperties.$filters = filters
app.config.globalProperties.$toObject = getObject
app.config.globalProperties.$apexcharts = ApexCharts
app.directive('money', MoneyDirective)
String.prototype.toCapitalize = toCapitalize

app.use(router)
app.use(store)
app.use(Quasar, quasarUserOptions)
app.use(VueApexCharts)

app.mount('#app')