const type = (process.env.NODE_ENV === 'development'? 'development' : 'production')

const configs = {
  production: {
    API_IP: 'https://api.production.planos.dev/trello'
  },
  development: {
    API_IP: 'http://10.0.0.6:3000/v1'
  }
}

export default configs[type]