const Members = () => import(/* webpackChunkName: "members" */ './Index.vue')

const index = {
  path: 'members',
  name: 'Membros',
  component: Members,
  meta: {
    icon: 'fas fa-users-cog',
    shown: true
  }
}

export default {
  index
}
