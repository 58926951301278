const Cards = () => import(/* webpackChunkName: "cards" */ './Index.vue')

const index = {
  path: 'cards',
  name: 'Cards',
  component: Cards,
  meta: {
    icon: 'fas fa-clipboard',
    shown: true
  }
}

export default {
  index
}
