import axiosInstance from './index'
import Loading from 'quasar/src/plugins/Loading.js';
import store from '../../store'
import router from '../../router'
import { sendNotification } from '../notify'

let requestsTotal = 0
let requestsCompleted = 0
const latencyThreshold = 100
const responseLatency = 50
const setComplete = () => {
  requestsTotal = 0
  requestsCompleted = 0
  if (Loading.isActive) {
    Loading.hide()
  }
}

const setup = () => {
  // Intercepção das requisições
  axiosInstance.interceptors.request.use(request => {
    const isLoading = request.headers.requestLoading === undefined ? true : request.headers.requestLoading
    if (request.baseURL.includes('planos') || request.baseURL.includes(':3000')) {
      const authToken = store.state.main.authToken
      if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`
      }
    }
    if (isLoading) {
      if (requestsTotal === 0) {
        if (latencyThreshold === 0) {
          Loading.show()
        } else {
          setTimeout(() => {
            if (requestsTotal !== requestsCompleted) {
              Loading.show()
            }
          }, latencyThreshold)
        }
      }
      requestsTotal++
    }
    return request
  }, error => {
    Promise.reject(error)
  })
  // Intercepção das respostas
  axiosInstance.interceptors.response.use(response => {
    response.body = response.data
    setTimeout(() => {
      requestsCompleted++
      if (requestsCompleted >= requestsTotal) {
        setComplete()
      }
    }, responseLatency)
    return response
  }, error => {
    setTimeout(() => {
      requestsCompleted++
      if (requestsCompleted >= requestsTotal) {
        setComplete()
      }
    }, responseLatency)
    if (error.response && error.response.status === 401) {
      store.dispatch('main/setAuthToken', null)
      store.dispatch('main/setUser', null)
      if (!error.response.config.url.includes('login')) {
        router.push({ path: '/login' })
        sendNotification({ color: 'info', message: 'Sua sessão expirou, efetue o login novamente.', timeout: 10000 })
      }
    }
    return Promise.reject(error.response)
  })
}

export default setup
