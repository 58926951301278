import { createStore, createLogger } from 'vuex'
import main from './modules/main'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    main
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})