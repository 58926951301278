import { createRouter, createWebHashHistory } from 'vue-router'
import beforeEach from './beforeEach'
// Components routes
import Login from '../views/login/index'
import Dashboard from '../views/dashboard/index'
import Members from '../views/members/index'
import Boards from '../views/boards/index'
import Labels from '../views/labels/index'
import LabelFilters from '../views/labelFilters/index'
import Sectors from '../views/sectors/index'
import Cards from '../views/cards/index'
// Layouts
const Default = () => import(/* webpackChunkName: "default" */ '../layouts/Default.vue')

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Default',
    component: Default,
    children: [
      Login.index,
      Dashboard.index,
      Boards.index,
      Members.index,
      Labels.index,
      LabelFilters.index,
      Sectors.index,
      Cards.index
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach(beforeEach)

export default router
